@import url("https://fonts.googleapis.com/css2?family=Rubik:wght@400;500;600;700&display=swap");
:root {
  --color-white-100: hsl(206, 5%, 98%);
  --color-white-200: hsl(206, 5%, 90%);
  --color-white-300: hsl(206, 5%, 80%);
  --color-white-400: hsl(206, 5%, 65%);
  --color-white-500: hsl(206, 5%, 50%);
  --color-white-600: hsl(206, 5%, 35%);
  --color-black-100: hsl(213, 20%, 9%);
  --color-black-200: hsl(213, 23%, 8%);
  --color-black-300: hsl(214, 21%, 6%);
  --color-black-400: hsl(210, 21%, 6%);
  --color-black-500: hsl(216, 22%, 4%);
  --color-black-600: hsl(220, 18%, 3%);
  --color-black-700: hsl(220, 27%, 2%);
  --color-black-800: hsl(180, 20%, 1%);
  --color-blue-100: hsl(214, 95%, 93%);
  --color-blue-200: hsl(213, 97%, 87%);
  --color-blue-300: hsl(212, 96%, 78%);
  --color-blue-400: hsl(213, 94%, 68%);
  --color-blue-500: hsl(217, 91%, 60%);
  --color-blue-600: hsl(221, 83%, 53%);
  --color-blue-700: hsl(224, 76%, 48%);
  --color-blue-800: hsl(226, 71%, 40%);
  --color-blue-900: hsl(224, 64%, 33%);
  --shadow-small: 0 1px 3px 0 rgba(0, 0, 0, 0.1),
     0 1px 2px 0 rgba(0, 0, 0, 0.06);
  --shadow-medium: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
     0 2px 4px -1px rgba(0, 0, 0, 0.06);
  --shadow-large: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
     0 4px 6px -2px rgba(0, 0, 0, 0.05);
}

*,
*::before,
*::after {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  list-style: none;
  list-style-type: none;
  text-decoration: none;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-rendering: optimizeLegibility;
}

html {
  font-size: 100%;
  box-sizing: inherit;
  scroll-behavior: smooth;
  height: -webkit-fill-available;
}

body {
  font-family: "Rubik", sans-serif;
  font-size: clamp(1rem, 2vw, 1.125rem);
  font-weight: 400;
  line-height: 1.5;
  height: -webkit-fill-available;
  color: var(--color-white-100);
  background-color: #212429;
}

main {
  overflow: hidden;
}

a,
button {
  cursor: pointer;
  border: none;
  outline: none;
  user-select: none;
  background: none;
  box-shadow: none;
  text-decoration: none;
}

img,
video {
  display: block;
  max-width: 100%;
  height: auto;
  object-fit: cover;
}

.section {
  margin: 0 auto;
  padding: 6rem 0 1rem;
}

.container {
  max-width: 75rem;
  height: auto;
  margin: 0 auto;
  padding: 0 1.25rem;
}

.centered {
  text-align: center;
  vertical-align: middle;
  margin-bottom: 1rem;
}
.heading-xl-pay {
  font-family: inherit !important;
  font-size: clamp(2.648rem, 6vw, 4.241rem) !important;
  font-weight: 700 !important;
  line-height: 1 !important;
  letter-spacing: -1px !important;
  position: relative;
}

.heading-xl {
  font-family: inherit !important;
  font-size: clamp(2.648rem, 6vw, 4.241rem) !important;
  font-weight: 700 !important;
  line-height: 1.15 !important;
  letter-spacing: -1px !important;
}
.heading-lg {
  font-family: inherit;
  font-size: clamp(2.179rem, 5vw, 3.176rem);
  font-weight: 700;
  line-height: 1.15;
  letter-spacing: -1px;
}
.heading-md {
  font-family: inherit;
  font-size: clamp(1.794rem, 4vw, 2.379rem);
  font-weight: 700;
  line-height: 1.25;
  letter-spacing: -1px;
}
.heading-sm {
  font-family: inherit;
  font-size: clamp(1.476rem, 3vw, 1.782rem);
  font-weight: 600;
  line-height: 1.5;
}
.heading-xs {
  font-family: inherit;
  font-size: clamp(1.215rem, 2vw, 1.335rem);
  font-weight: 500;
  line-height: 1.5;
}

.paragraph {
  font-family: inherit;
  font-size: clamp(1rem, 2vw, 1.125rem);
  line-height: inherit;
  max-width: 95%;
  height: auto;
  text-transform: unset;
  color: var(--color-white-200);
}

.btn {
  display: inline-block;
  font-family: inherit;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.5;
  text-align: center;
  vertical-align: middle;
  white-space: nowrap;
  user-select: none;
  outline: none;
  border: none;
  border-radius: 0.25rem;
  text-transform: unset;
  transition: all 0.3s ease-in-out;
}
.btn-inline {
  display: inline-flex;
  align-items: center;
  justify-content: center;
  column-gap: 0.5rem;
}
.btn-darken {
  padding: 0.75rem 2rem;
  color: var(--color-white-100);
  background-color: var(--color-black-200);
  box-shadow: var(--shadow-medium);
}
.btn-neutral {
  padding: 0.75rem 2rem;
  color: var(--color-black-500);
  background-color: var(--color-white-100);
  box-shadow: var(--shadow-medium);
}

.header {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  width: 100%;
  height: auto;
  margin: 0 auto;
  transition: all 0.35s ease;
}
.header.on-scroll {
  background: var(--color-black-300);
  box-shadow: var(--shadow-medium);
}

.navbar {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
  column-gap: 1.25rem;
  width: 100%;
  height: 4.25rem;
  margin: 12px auto;
}

.brand {
  font-family: inherit;
  font-size: 1.5rem;
  font-weight: 600;
  line-height: 1.5;
  letter-spacing: -1px;
  color: var(--color-white-100);
  display: flex;
}

.menu {
  position: fixed;
  top: -100%;
  left: 0;
  width: 100%;
  height: auto;
  padding: 4rem 0 3rem;
  overflow: hidden;
  background-color: var(--color-black-300);
  box-shadow: var(--shadow-medium);
  transition: all 0.4s ease-in-out;
}
.menu.is-active {
  top: 0;
  width: 100%;
  height: auto;
}
.menu-inner {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  row-gap: 1.25rem;
}
.menu-link {
  font-family: inherit;
  font-size: 1rem;
  font-weight: 500;
  line-height: 1.5;
  color: var(--color-white-100);
  text-transform: uppercase;
  transition: all 0.3s ease;
}
.menu-block {
  display: inline-block;
  font-family: inherit;
  font-size: 0.875rem;
  font-weight: 500;
  line-height: 1.25;
  user-select: none;
  white-space: nowrap;
  text-align: center;
  margin-left: auto;
  padding: 0.65rem 1.5rem;
  border-radius: 3rem;
  text-transform: capitalize;
  color: var(--color-white);
  background-color: var(--color-blue-600);
  box-shadow: var(--shadow-medium);
  transition: all 0.3s ease-in-out;
}
@media only screen and (min-width: 48rem) {
  .menu {
    position: relative;
    top: 0;
    width: auto;
    height: auto;
    padding: 0rem;
    margin-right: auto;
    background: none;
    box-shadow: none;
  }
  .menu-inner {
    display: flex;
    flex-direction: row;
    column-gap: 3rem;
    margin: 0 auto;
  }
  .menu-link {
    text-transform: capitalize;
  }
  .menu-block {
    margin-left: 1rem;
  }

}

.burger {
  position: relative;
  display: block;
  cursor: pointer;
  user-select: none;
  order: -1;
  z-index: 10;
  width: 1.65rem;
  height: 1.15rem;
  border: none;
  outline: none;
  background: none;
  visibility: visible;
  transform: rotate(0deg);
  transition: 0.35s ease;
}
@media only screen and (min-width: 48rem) {
  .burger {
    display: none;
    visibility: hidden;
  }
}
.burger-line {
  position: absolute;
  display: block;
  left: 0;
  width: 100%;
  height: 2px;
  border: none;
  outline: none;
  opacity: 1;
  border-radius: 1rem;
  transform: rotate(0deg);
  background-color: var(--color-white-100);
  transition: 0.25s ease-in-out;
}
.burger-line:nth-child(1) {
  top: 0px;
}
.burger-line:nth-child(2) {
  top: 0.5rem;
  width: 70%;
}
.burger-line:nth-child(3) {
  top: 1rem;
}
.burger.is-active .burger-line:nth-child(1) {
  top: 0.5rem;
  transform: rotate(135deg);
}
.burger.is-active .burger-line:nth-child(2) {
  opacity: 0;
  visibility: hidden;
}
.burger.is-active .burger-line:nth-child(3) {
  top: 0.5rem;
  transform: rotate(-135deg);
}

.banner-column {
  position: relative;
  display: grid;
  align-items: center;
  row-gap: 3rem;
}
@media only screen and (min-width: 48rem) {
  .banner-column {
    grid-template-columns: repeat(2, minmax(0, 1fr));
    justify-content: center;
  }
}
@media only screen and (min-width: 64rem) {
  .banner-column {
    grid-template-columns: 1fr max-content;
    column-gap: 4rem;
    margin-top: 3rem;
  }
}
.banner-image {
  display: block;
  max-width: 7rem;
  height: auto;
  margin-top: 2rem;
  object-fit: cover;
  justify-self: center;
}
@media only screen and (min-width: 48rem) {
  .banner-image {
    order: 1;
    max-width: 15rem;
    height: auto;
  }
}
@media only screen and (min-width: 64rem) {
  .banner-image {
    margin-top:0rem;
    height: auto;
    margin-right: 5rem;
  }
}
.banner-inner {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  row-gap: 1.75rem;
}
.banner-links {
  position: absolute;
  display: grid;
  justify-items: center;
  row-gap: 0.5rem;
  backdrop-filter: blur(108.731px);
    background: linear-gradient(135deg,rgba(245,247,250,.12),rgba(245,247,250,.06) 52.14%,rgba(245,247,250,0));
    border: 1px solid rgba(245,247,250,.06);
    border-radius: 20px 0 0 12px;
    box-shadow: 0 1px 1px rgb(9 13 20 / 40%), -4px -4px 8px rgb(224 224 255 / 4%), 8px 8px 24px rgb(9 13 20 / 40%);
    position: fixed;
    right: 0;
    top: 50%;
    -webkit-transform: translateY(-50%);
    transform: translateY(-50%);
    transition: padding-right .4s;
    z-index: 5;
    padding: 5px;
}
@media only screen and (min-width: 64rem) {
  .banner-links {
    opacity: 1;
    visibility: visible;
  }
}
// .banner-links > * {
//   font-size: 2.25rem;
//   line-height: 1.25;
//   color: rgba(224,224,255,.6);
// }

@keyframes example {
  0% {
    transform: scale(1) rotate(0);
  }
  50% {
    transform: scale(1.07) rotate(10deg);
  }
}
.animation-svg {
  animation: example infinite 6s;
  max-width: 100%
}
.mobile-list-ul li{
  margin-bottom:10px;
  margin-left: 10%;
  width: 100%;
}
.mobile-list-ul li button{
 font-weight: 700;
 color: #fff;
 text-transform:capitalize
}
.before-styles-pay::before{
  background: linear-gradient(135deg, #0fa, #4579f5 51.65%, #9c42f5 99.83%);
  border-radius: 366px;
  box-shadow: 0 0 1px rgb(69 121 245 / 24%), 0 2px 4px -1px rgb(27 10 82 / 12%), 0 16px 24px rgb(69 121 245 / 24%), 0 8px 8px -4px rgb(69 121 245 / 12%);
  content: "";
  height: 304px;
  left: -5%;
  position: absolute;
  top: 20%;
  width: 304px;
}
