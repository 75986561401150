/* @media query */

/* media queries */
@media (max-width: 767px) {
  .mobile-btn-layout {
    font-size: 10px !important;
  }
  .mobile-dsplay-none {
    display: none !important;
  }
  .before-styles:before {
    top: -4% !important;
    left: -5% !important;
  }
  .before-styles-btafpay:before {
    display: none;
  }
  .tuffy-img {
    height: auto !important;
    margin-bottom: -72px !important;
    margin-top: -269px !important;
    position: absolute;
    width: 200px !important;
  }
  .tuffy-secure-img {
    margin-top: 0 !important;
    margin-left: 0 !important;
    width: auto !important;
  }
  .secure-head {
    margin-top: 5rem !important;
  }
  .secure-card {
    padding: 70px 26px 20px 20px !important;
  }
  .whatis-card {
    padding: 70px 26px 20px 20px !important;
  }
  .header {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 100;
    width: 100%;
    height: auto;
    margin: 0 auto;
    transition: all 0.35s ease;
  }
}

/* end media query  */

.whatis-card-box {
  max-width: 1096px;
  background-color: transparent !important;
  position: relative;
  overflow: visible !important;
}
.whatis-card {
  backdrop-filter: blur(108.731px);
  background: linear-gradient(
    135deg,
    rgba(245, 247, 250, 0.12),
    rgba(245, 247, 250, 0.06) 52.14%,
    rgba(245, 247, 250, 0)
  ) !important;
  display: flex;
  height: auto;
  justify-content: space-between;
  margin: 0 auto;
  backdrop-filter: blur(108.731px);
  border: 1px solid rgba(245, 247, 250, 0.06);
  border-radius: 24px !important;
  box-shadow: 0 1px 1px rgb(9 13 20 / 40%), -4px -4px 8px rgb(224 224 255 / 4%),
    8px 8px 24px rgb(9 13 20 / 40%) !important;
  padding: 72px 26px 72px 72px;
  position: relative;
  width: 100%;
  z-index: 1;
  overflow: visible !important;
}
.what-head {
  font-style: normal;
  font-weight: 700 !important;
  letter-spacing: 1px;
  line-height: 36px;
  color: #fff;
}
.tier-head {
  font-style: normal;
  font-weight: 700 !important;
  letter-spacing: 1px;
  line-height: 36px;
  color: #fff;
}
.tuffy-img {
  height: 100%;
  margin-bottom: -72px;
  margin-top: -330px;
  width: 390px;
}
.what-head svg {
  position: absolute;
  left: 128px;
  top: -7px;
  width: 95px;
  z-index: -1;
}
.tier-head svg {
  position: absolute;
  left: 49%;
  top: -6px;
  width: 76px;
  z-index: -1;
}
.before-styles:before {
  background: linear-gradient(135deg, #0fa, #4579f5 51.65%, #9c42f5 99.83%);
  border-radius: 366px;
  box-shadow: 0 0 1px rgb(69 121 245 / 24%), 0 2px 4px -1px rgb(27 10 82 / 12%),
    0 16px 24px rgb(69 121 245 / 24%), 0 8px 8px -4px rgb(69 121 245 / 12%);
  content: "";
  height: 304px;
  left: -11%;
  position: absolute;
  top: -40%;
  width: 304px;
}
.tier-card {
  backdrop-filter: blur(108.731px);
  background: linear-gradient(
    135deg,
    rgba(245, 247, 250, 0.12),
    rgba(245, 247, 250, 0.06) 52.14%,
    rgba(245, 247, 250, 0)
  ) !important;
  border: 1px solid rgba(245, 247, 250, 0.06);
  border-radius: 24px !important;
  box-shadow: 0 1px 1px rgb(9 13 20 / 40%), -4px -4px 8px rgb(224 224 255 / 4%),
    8px 8px 24px rgb(9 13 20 / 40%) !important;
  display: flex;
  flex-direction: column;
  margin: 24px 0px 8px;
  max-width: 312px;
  padding: 32px;
  width: 100%;
}
.tire-content {
  color: #fff;
}
.tire-content .caption {
  font-size: 12px;
  line-height: 2;
}
.tire-content .caption::before {
  content: "✓";
  left: 5%;
  position: absolute;
}
.tier-minDiv {
  min-height: 150px;
}
.tire-btn-diamond {
  background: linear-gradient(
    135deg,
    rgb(0, 255, 170) 0%,
    rgb(69, 121, 245) 51.65%,
    rgb(156, 66, 245) 99.83%
  );
  box-shadow: rgb(69 121 245 / 24%) 0px 0px 1px,
    rgb(27 10 82 / 12%) 0px 2px 4px -1px, rgb(69 121 245 / 24%) 0px 16px 24px,
    rgb(69 121 245 / 12%) 0px 8px 8px -4px !important;
  border-radius: 3rem !important;
  margin-top: 2rem !important;
}
.tire-btn-platinum {
  background: linear-gradient(
    135deg,
    rgb(102, 255, 0) 0%,
    rgb(22, 184, 98) 51.88%,
    rgb(0, 133, 143) 100%
  );
  box-shadow: rgb(22 184 98 / 24%) 0px 0px 1px,
    rgb(10 70 82 / 12%) 0px 2px 4px -1px, rgb(22 184 98 / 24%) 0px 16px 24px,
    rgb(22 184 98 / 12%) 0px 8px 8px -4px !important;
  border-radius: 3rem !important;
  margin-top: 2rem !important;
}
.tire-btn-gold {
  background: linear-gradient(
    135deg,
    rgb(255, 0, 170) 0%,
    rgb(156, 66, 245) 50.18%,
    rgb(93, 45, 225) 100%
  );
  box-shadow: rgb(156 66 245 / 24%) 0px 0px 1px,
    rgb(27 10 82 / 12%) 0px 2px 4px -1px, rgb(156 66 245 / 24%) 0px 16px 24px,
    rgb(156 66 245 / 12%) 0px 8px 8px -4px !important;
  border-radius: 3rem !important;
  margin-top: 2rem !important;
}
.tire-btn-silver {
  background: linear-gradient(
    135deg,
    rgb(255, 94, 0) 0%,
    rgb(245, 69, 98) 54.11%,
    rgb(195, 45, 225) 100%
  );
  box-shadow: rgb(245 69 98 / 24%) 0px 0px 1px,
    rgb(27 10 82 / 12%) 0px 2px 4px -1px, rgb(245 69 98 / 24%) 0px 16px 24px,
    rgb(245 69 98 / 12%) 0px 8px 8px -4px !important;
  border-radius: 3rem !important;
  margin-top: 2rem !important;
}
.tire-buy-card {
  backdrop-filter: blur(108.731px);
  -webkit-backdrop-filter: blur(108.731px);
  background: linear-gradient(
    135deg,
    rgba(245, 247, 250, 0.12),
    rgba(245, 247, 250, 0.06) 52.14%,
    rgba(245, 247, 250, 0)
  ) !important;
  border: 1px solid rgba(245, 247, 250, 0.06);
  border-radius: 24px !important;
  box-shadow: 0 1px 1px rgb(9 13 20 / 40%), -4px -4px 8px rgb(224 224 255 / 4%),
    8px 8px 24px rgb(9 13 20 / 40%) !important;
  box-sizing: border-box;
  height: 100%;
  padding: 40px;
  position: relative;
}
.title-address {
  background: linear-gradient(135deg, #0fa, #4579f5 51.65%, #9c42f5 99.83%);
  border-radius: 72px;
  box-shadow: 0 0 1px rgb(69 121 245 / 24%), 0 2px 4px -1px rgb(27 10 82 / 12%),
    0 16px 24px rgb(69 121 245 / 24%), 0 8px 8px -4px rgb(69 121 245 / 12%);
  color: #fff;
  font-size: 16px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  padding: 12px 72px 12px 32px;
  position: relative;
}

.qr-code {
  margin-top: 11px;
  border-radius: 12px;
  padding: 5px;
}
.address-btn {
  align-items: center;
  background: rgba(0, 255, 170, 0.03);
  border: 1px solid #0fa !important;
  border-radius: 72px !important;
  cursor: pointer !important;
  display: flex;
  color: #fff !important;

  justify-content: center;
  padding: 12px 0 !important;
  width: 240px !important;
  text-transform: capitalize !important;
  font-weight: 800 !important;
}
/* secure  */
.secure-card-box {
  max-width: 1096px;
  background-color: transparent !important;
  position: relative;
  overflow: visible !important;
}
.before-styles-secure:before {
  background: linear-gradient(135deg, #0fa, #4579f5 51.65%, #9c42f5 99.83%);
  border-radius: 366px;
  box-shadow: 0 0 1px rgb(69 121 245 / 24%), 0 2px 4px -1px rgb(27 10 82 / 12%),
    0 16px 24px rgb(69 121 245 / 24%), 0 8px 8px -4px rgb(69 121 245 / 12%);
  content: "";
  height: 304px;
  right: -9%;
  position: absolute;
  top: 12%;
  width: 304px;
}
.secure-card {
  backdrop-filter: blur(108.731px);
  background: linear-gradient(
    135deg,
    rgba(245, 247, 250, 0.12),
    rgba(245, 247, 250, 0.06) 52.14%,
    rgba(245, 247, 250, 0)
  ) !important;
  display: flex;
  flex-wrap: wrap;
  height: auto;
  justify-content: space-between;
  margin: 0 auto;
  backdrop-filter: blur(108.731px);
  border: 1px solid rgba(245, 247, 250, 0.06);
  border-radius: 24px !important;
  box-shadow: 0 1px 1px rgb(9 13 20 / 40%), -4px -4px 8px rgb(224 224 255 / 4%),
    8px 8px 24px rgb(9 13 20 / 40%) !important;
  padding: 72px 26px 72px 72px;
  position: relative;
  width: 100%;
  z-index: 1;
  overflow: visible !important;
}
.secure-head {
  font-style: normal;
  font-weight: 700 !important;
  letter-spacing: 1px;
  line-height: 36px;
  color: #fff;
}
.secure-head svg {
  position: absolute;
  left: 70px;
  top: 33px;
  width: 95px;
  z-index: -1;
}
.tuffy-secure-img {
  height: 100%;
  margin-bottom: -72px;
  margin-top: -220px;
  /* margin-left: 30%; */
  width: 328px;
}

.buy-btn {
  background: linear-gradient(
    135deg,
    #0fa,
    #4579f5 51.65%,
    #9c42f5 99.83%
  ) !important;
  border-radius: 72px !important;
  box-shadow: 0 0 1px rgb(69 121 245 / 24%), 0 2px 4px -1px rgb(27 10 82 / 12%),
    0 16px 24px rgb(69 121 245 / 24%), 0 8px 8px -4px rgb(69 121 245 / 12%) !important;
  padding: 10px !important;
}
.connected-btn {
  background: linear-gradient(
    135deg,
    #21a200,
    #256f00 51.65%,
    #00ba4d 99.83%
  ) !important;
  border-radius: 72px !important;
  box-shadow: 0 0 1px rgb(12 94 15 / 24%), 0 2px 4px -1px rgb(11 82 10 / 12%),
    0 16px 24px rgb(72 245 69 / 24%), 0 8px 8px -4px rgb(69 245 77 / 12%) !important;
  color: #fff;
}
.connect-btn {
  background: linear-gradient(
    135deg,
    #0fa,
    #4579f5 51.65%,
    #9c42f5 99.83%
  ) !important;
  border-radius: 72px !important;
  box-shadow: 0 0 1px rgb(69 121 245 / 24%), 0 2px 4px -1px rgb(27 10 82 / 12%),
    0 16px 24px rgb(69 121 245 / 24%), 0 8px 8px -4px rgb(69 121 245 / 12%) !important;
  color: #fff;
}
.claim-now-btn {
  background: linear-gradient(
    135deg,
    #0fa,
    #20ca5b 51.65%,
    #008c1a 99.83%
  ) !important;
  border-radius: 72px !important;
  box-shadow: 0 0 1px #00ffaa69, 0 2px 4px -1px #00ffaa66, 0 16px 24px #00ffaa2e,
    0 8px 8px -4px rgb(69 121 245 / 12%) !important;
  color: #fff;
}
.buy-taf-btn {
  background: linear-gradient(
    135deg,
    #2d2f33,
    #282a2e 51.37%,
    #212429 99.14%
  ) !important;
  box-shadow: 0 0 1px #6f0, 0 2px 4px -1px #16b862, 0 6px 12px #00858f,
    0 8px 8px -4px #00858f, inset 0 2px 6px #00858f !important;
  font-size: 16px;
  padding: 12px 24px;
  width: 135px;
}
.footer-card-box {
  background-color: #1c1c1c !important;
  border-radius: 25px !important;
  display: grid;
  padding: 50px;
  text-align: center;
}
.footer-menus {
  color: rgba(224, 224, 255, 0.6);
  font-weight: 700;
  margin-top: 8px !important;
}
.footer-menus a {
  color: rgba(224, 224, 255, 0.6);
  margin: 0;
  font-family: "Roboto","Helvetica","Arial",sans-serif;
  font-weight: 400;
  font-size: 1rem;
  line-height: 1;
  letter-spacing: 0.00938em;
  margin-top: 16px;
}
.footer-icon {
  margin-top: 1rem;
}
.footer-icon a {
  color: #fff;
  margin-right: 10px;
  font-size: 1.5rem;
}
.footer-card .css-6d055j-MuiTypography-root {
  margin-top: 5px;
}
.subscribe-btn {
  background: linear-gradient(
    135deg,
    #2d2f33,
    #282a2e 51.37%,
    #212429 99.14%
  ) !important;
  box-shadow: 0 0 1px rgb(0 255 170 / 12%), 0 2px 4px -1px rgb(27 10 82 / 6%),
    0 16px 24px rgb(0 255 170 / 12%), 0 8px 8px -4px rgb(0 255 170 / 6%),
    inset 0 2px 6px rgb(0 255 170 / 40%) !important;
  box-shadow: 0 0 1px rgb(0 255 170 / 12%), 0 2px 4px -1px rgb(27 10 82 / 6%),
    0 16px 24px rgb(0 255 170 / 12%), 0 8px 8px -4px rgb(0 255 170 / 6%),
    inset 0 2px 6px rgb(0 255 170 / 40%) !important;
  border-radius: 2rem !important;
  text-transform: capitalize !important;
  font-weight: 700 !important;
}
input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}
div::-webkit-scrollbar {
  width: 1em;
  height: 10px;
}
div::-webkit-scrollbar-track {
  -webkit-box-shadow: inset 0 0 3px rgba(0, 0, 0, 0.3);
}
div::-webkit-scrollbar-thumb {
  background: linear-gradient(
    135deg,
    #0fa,
    #4579f5 51.65%,
    #9c42f5 99.83%
  ) !important;
  border-radius: 1rem;
}
